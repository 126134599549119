import { Image } from '@ffn/ui';
import { useBrand } from 'lib/hooks';

export const FDRLogo = ({ isdesktop }) => {
  const brand = useBrand();
  return (
    <Image
      alt="Freedom Debt Relief logo"
      src={brand('logo.main')}
      height={'48px'}
      padding={isdesktop ? '0' : '8px 0px'}
    />
  );
};
